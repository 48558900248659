import { ModelTraining } from "./ModelTraining";

function App() {
  return (
    <div>
      <ModelTraining />
      <footer className="flex flex-col items-center justify-center gap-2 mt-12 text-xs text-center text-gray-500">
        <div>This is for testing purposes only. Do not use for production.</div>
        <div>
          View the code on{" "}
          <a
            href="https://github.com/starbornone"
            title="starbornone on GitHub"
          >
            GitHub
          </a>
          . Email icon created by{" "}
          <a
            href="https://www.flaticon.com/free-icons/email"
            title="Email icon created by Uniconlabs"
          >
            Uniconlabs
          </a>
          .
        </div>
      </footer>
    </div>
  );
}

export default App;
