import { RowData } from "@/data";
import "./ScoreTable.css";

interface ScoreTableProps {
  dataset: RowData[];
  predictions: number[];
}

export function ScoreTable({ dataset, predictions }: ScoreTableProps) {
  return (
    <table className="scores-table">
      <thead className="scores-table__head">
        <tr className="scores-table__row">
          <th className="scores-table__cell">Recipients</th>
          <th className="scores-table__cell">Opened (%)</th>
          <th className="scores-table__cell">Clicked (%)</th>
          <th className="scores-table__cell">Bounces (%)</th>
          <th className="scores-table__cell">Unsubscribes (%)</th>
          <th className="scores-table__cell">Target Score</th>
          <th className="scores-table__cell">Predicted Score</th>
        </tr>
      </thead>
      <tbody className="scores-table__body">
        {dataset.map((row, i) => {
          const target = row.targetScore;
          const predicted = predictions[i];
          const diff = Math.abs(predicted - target);

          const isClose = diff <= 2;
          const isFar = diff > 5;

          let cssClass = "";
          if (isClose) cssClass = "score--close";
          else if (isFar) cssClass = "score--far";

          return (
            <tr className="scores-table__row" key={i}>
              <td className="scores-table__cell">{row.recipients}</td>
              <td className="scores-table__cell">{row.opened}</td>
              <td className="scores-table__cell">{row.clicked}</td>
              <td className="scores-table__cell">{row.bounces}</td>
              <td className="scores-table__cell">{row.unsubscribes}</td>
              <td className="scores-table__cell">{target}</td>
              <td
                className={`scores-table__cell${
                  cssClass ? " " + cssClass : ""
                }`}
              >
                {predicted.toFixed(2)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
