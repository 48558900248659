export interface RowData {
  recipients: number;
  opened: number;
  clicked: number;
  bounces: number;
  unsubscribes: number;
  targetScore: number;
}

export const dataset: RowData[] = [
  {
    recipients: 459,
    opened: 55.8,
    clicked: 2.6,
    bounces: 0.87,
    unsubscribes: 0.22,
    targetScore: 85,
  },
  {
    recipients: 440,
    opened: 48.7,
    clicked: 0.7,
    bounces: 2.95,
    unsubscribes: 0,
    targetScore: 70,
  },
  {
    recipients: 458,
    opened: 55.2,
    clicked: 1.3,
    bounces: 1.09,
    unsubscribes: 0.66,
    targetScore: 80,
  },
  {
    recipients: 953,
    opened: 48.5,
    clicked: 0.1,
    bounces: 0.63,
    unsubscribes: 0.21,
    targetScore: 75,
  },
  {
    recipients: 989,
    opened: 41.2,
    clicked: 2.4,
    bounces: 2.12,
    unsubscribes: 0.1,
    targetScore: 70,
  },
  {
    recipients: 969,
    opened: 50.4,
    clicked: 2.2,
    bounces: 2.89,
    unsubscribes: 0.64,
    targetScore: 75,
  },
  {
    recipients: 984,
    opened: 51.1,
    clicked: 0.2,
    bounces: 2.03,
    unsubscribes: 0.41,
    targetScore: 70,
  },
  {
    recipients: 437,
    opened: 57.0,
    clicked: 12.2,
    bounces: 0.46,
    unsubscribes: 0,
    targetScore: 100,
  },
  {
    recipients: 953,
    opened: 48.5,
    clicked: 0.1,
    bounces: 0.63,
    unsubscribes: 0.21,
    targetScore: 75,
  },
  {
    recipients: 1017,
    opened: 54.0,
    clicked: 1.8,
    bounces: 2.06,
    unsubscribes: 1.1,
    targetScore: 70,
  },
  {
    recipients: 1002,
    opened: 53.9,
    clicked: 2.9,
    bounces: 2.0,
    unsubscribes: 1.22,
    targetScore: 70,
  },
  {
    recipients: 456,
    opened: 57.8,
    clicked: 4.0,
    bounces: 0.66,
    unsubscribes: 0.88,
    targetScore: 90,
  },
  {
    recipients: 437,
    opened: 50.2,
    clicked: 1.2,
    bounces: 0.69,
    unsubscribes: 0.46,
    targetScore: 80,
  },
  {
    recipients: 456,
    opened: 60,
    clicked: 15,
    bounces: 0.9,
    unsubscribes: 0.1,
    targetScore: 100,
  },
  {
    recipients: 459,
    opened: 50,
    clicked: 10,
    bounces: 1.5,
    unsubscribes: 0.2,
    targetScore: 90,
  },
  {
    recipients: 458,
    opened: 35,
    clicked: 7,
    bounces: 2,
    unsubscribes: 0.3,
    targetScore: 80,
  },
  {
    recipients: 953,
    opened: 25,
    clicked: 5,
    bounces: 2.5,
    unsubscribes: 0.6,
    targetScore: 70,
  },
  {
    recipients: 989,
    opened: 15,
    clicked: 2,
    bounces: 2.6,
    unsubscribes: 0.7,
    targetScore: 60,
  },
];
