import React, { useState } from "react";
import { manualPredict, NormalizationInfo } from "@/model/training";
import "./PredictionForm.css";

interface PredictionFormProps {
  weights: number[][][];
  biases: number[][];
  normalizationInfo: NormalizationInfo;
}

const presets = {
  Excellent: {
    opened: 60,
    clicked: 15,
    unsubscribes: 0.1,
    bounces: 0.9,
  },
  Great: {
    opened: 50,
    clicked: 10,
    unsubscribes: 0.2,
    bounces: 1.5,
  },
  Good: {
    opened: 35,
    clicked: 7,
    unsubscribes: 0.3,
    bounces: 2,
  },
  Average: {
    opened: 25,
    clicked: 5,
    unsubscribes: 0.6,
    bounces: 2.5,
  },
  Poor: {
    opened: 15,
    clicked: 2,
    unsubscribes: 0.7,
    bounces: 2.6,
  },
};

interface PredictionFormProps {
  weights: number[][][];
  biases: number[][];
  normalizationInfo: NormalizationInfo;
}

export function PredictionForm({
  weights,
  biases,
  normalizationInfo,
}: PredictionFormProps) {
  const [formValues, setFormValues] = useState({
    opened: "",
    clicked: "",
    unsubscribes: "",
    bounces: "",
  });
  const [prediction, setPrediction] = useState<number | null>(null);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handlePreset(preset: keyof typeof presets): void {
    const selectedPreset = presets[preset];
    setFormValues({
      opened: selectedPreset.opened.toString(),
      clicked: selectedPreset.clicked.toString(),
      unsubscribes: selectedPreset.unsubscribes.toString(),
      bounces: selectedPreset.bounces.toString(),
    });
  }

  function handleSubmit(e: React.FormEvent): void {
    e.preventDefault();

    const inputRow = {
      opened: parseFloat(formValues.opened) || 0,
      clicked: parseFloat(formValues.clicked) || 0,
      unsubscribes: parseFloat(formValues.unsubscribes) || 0,
      bounces: parseFloat(formValues.bounces) || 0,
      recipients: 0,
      targetScore: 0,
    };

    const predictionResult = manualPredict(
      [inputRow],
      weights,
      biases,
      normalizationInfo
    )[0];
    setPrediction(predictionResult);
  }

  return (
    <div className="form-container">
      <div className="presets">
        {Object.keys(presets).map((preset) => (
          <button
            key={preset}
            className="button"
            onClick={() => handlePreset(preset as keyof typeof presets)}
          >
            {preset}
          </button>
        ))}
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__group">
          <label className="form__label" htmlFor="opened">
            Opened (%)
          </label>
          <input
            className="form__input"
            type="number"
            name="opened"
            id="opened"
            value={formValues.opened}
            onChange={handleInputChange}
            max="100"
          />
        </div>
        <div className="form__group">
          <label className="form__label" htmlFor="clicked">
            Clicked (%)
          </label>
          <input
            className="form__input"
            type="number"
            name="clicked"
            id="clicked"
            value={formValues.clicked}
            onChange={handleInputChange}
            max="100"
          />
        </div>
        <div className="form__group">
          <label className="form__label" htmlFor="unsubscribes">
            Unsubscribes (%)
          </label>
          <input
            className="form__input"
            type="number"
            name="unsubscribes"
            id="unsubscribes"
            value={formValues.unsubscribes}
            onChange={handleInputChange}
            max="100"
          />
        </div>
        <div className="form__group">
          <label className="form__label" htmlFor="bounces">
            Bounces (%)
          </label>
          <input
            className="form__input"
            type="number"
            name="bounces"
            id="bounces"
            value={formValues.bounces}
            onChange={handleInputChange}
            max="100"
          />
        </div>
        <button className="button" type="submit">
          Predict
        </button>
      </form>
      {prediction !== null && (
        <div className="form__result">
          Predicted Score: <strong>{prediction.toFixed(2)}</strong>
        </div>
      )}
    </div>
  );
}
